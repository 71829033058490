import classNames from 'classnames';
import { useMemo } from 'react';
import { motion, useAnimationControls } from 'framer-motion';

import Keyboard from 'src/elements/Keyboard';
import Letter from 'src/elements/Letter';

import styles from './Guess.module.scss';

export function Guess({ className, value, control, winControl, onChange }) {
    const guess = useMemo(() => ['', '', '', '', ''].map((l, i) => value[i] ?? ''), [value]);
    const letterControl = useAnimationControls();

    function handleKeyboardChange(key) {
        if (key === "backspace" && value.length >= 1) {
            onChange(value.slice(0, value.length - 1));
            return;
        }

        if (key.length > 1 || value.length >= 5) {
            return;
        }

        letterControl.start({
            scale: [1, 1.1, 1],
            transition: { type: 'spring', duration: 0.1 },
        }).then(letterControl.stop);

        onChange(value + key);
    }

    const nextEmptyIndex = guess.findIndex((element) => element === "");

    return (
        <div className={classNames(styles.container, className)}>
            <motion.div className={styles.guess} animate={control}>
                {
                    guess.map((letter, index) => (
                        <motion.div
                            key={index}
                            animate={winControl}
                            transition={{
                                type: 'spring',
                                bounce: 0.9,
                                duration: 0.4,
                                delay: index * 0.1,
                            }}
                        >
                            <motion.div key={index} animate={nextEmptyIndex === index ? letterControl : undefined}>
                                <Letter size="large">{letter}</Letter>
                            </motion.div>
                        </motion.div>
                    ))
                }
            </motion.div>

            <Keyboard onChange={handleKeyboardChange} />
        </div>
    );
}
