import { createContext, useContext, useState } from "react";

import app from 'src/translations/en_US/app.json';
import words from 'src/translations/en_US/words.json';

const TranslationContext = createContext();

export function TranslationContextProvider({ children }) {
    const [translation] = useState({ ...app, ...words });

    return (
        <TranslationContext.Provider value={translation}>
            {children}
        </TranslationContext.Provider>
    );
}

export function useTranslation() {
    const context = useContext(TranslationContext);

    if (!context) {
        throw new Error('useTranslation must be used within TranslationContextProvider');
    }

    return context;
}
