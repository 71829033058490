import { useEffect } from "react";
import { createContext, useContext, useReducer } from "react";

import settingsReducer, { initialState } from "./settings-reducer";

const SettingsContext = createContext();

export function SettingsContextProvider({ children }) {
    const [settings, settingsDispatch] = useReducer(settingsReducer, initialState());

    useEffect(() => {
        if (settings.darkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [settings.darkMode]);

    return (
        <SettingsContext.Provider value={[settings, settingsDispatch]}>
            {children}
        </SettingsContext.Provider>
    );
}

export function useSettings() {
    const context = useContext(SettingsContext);

    if (!context) {
        throw new Error('useSettings must be used within SettingsContextProvider');
    }

    return context;
}
