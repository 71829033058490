import { useEffect } from "react";
import { TiBackspaceOutline } from 'react-icons/ti';

import { useTranslation } from "src/contexts/Translation";

import Letter from "../Letter";

import styles from './Keyboard.module.scss';

export function Keyboard({ onChange }) {
    const t = useTranslation();

    useEffect(() => {
        document.addEventListener('keyup', handleOnKeyUp);

        return () => {
            document.removeEventListener('keyup', handleOnKeyUp);
        };
    }, [onChange]);

    function handleOnKeyUp(e) {
        const alphabet = t.keyboard.alphabet.split('');
        const value = e.key.toLowerCase();

        if (!alphabet.includes(value) && value !== "backspace") {
            return;
        }

        onChange(value);
    }

    function handleClick(letter) {
        return () => onChange(letter);
    }

    return (
        <div className={styles.container}>
            <div>
                {t.keyboard.alphabet.split('').slice(0, 10).map(letter => <Letter key={letter} size="thin" onClick={handleClick(letter)}>{letter}</Letter>)}
            </div>

            <div>
                {t.keyboard.alphabet.split('').slice(10, 19).map(letter => <Letter key={letter} size="thin" onClick={handleClick(letter)}>{letter}</Letter>)}
            </div>

            <div>
                {t.keyboard.alphabet.split('').slice(19, 26).map(letter => <Letter key={letter} size="thin" onClick={handleClick(letter)}>{letter}</Letter>)}
                <Letter className={styles.backspace} size="thin" onClick={handleClick("backspace")}>&nbsp;&nbsp;<TiBackspaceOutline />&nbsp;&nbsp;</Letter>
            </div>
        </div>
    );
}
