import classNames from 'classnames';

import styles from './Letter.module.scss';

export function Letter({ className, size = 'medium', onClick, children, ...props }) {
    function handleClick() {
        onClick(children);
    }

    if (onClick) {
        return (
            <button {...props} className={classNames(styles.button, styles[size], className)} onClick={handleClick}>
                {children}
            </button>

        );
    }

    return (
        <div {...props} className={classNames(styles.readonly, styles[size], className)}>
            {children}
        </div>
    );
}
