import { createContext, useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { TbX } from 'react-icons/tb';

import styles from './Modal.module.scss';

const ModalContext = createContext();

export function ModalContextProvider({ children }) {
    const [modal, setModal] = useState();

    useEffect(() => {
        if (modal) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [modal]);

    function handleCloseModal() {
        setModal(undefined);
    }

    return (
        <ModalContext.Provider value={setModal}>
            {
                modal && <>
                    <motion.div
                        onClick={handleCloseModal}
                        className={styles.backdrop}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                    </motion.div>

                    <AnimatePresence>
                        <motion.div className={styles.container} tabIndex="-1">
                            <button className={styles.close} onClick={handleCloseModal}><TbX /></button>
                            {modal}
                        </motion.div>
                    </AnimatePresence>
                </>
            }

            {children}
        </ModalContext.Provider>
    );
}

export function useModal() {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error('useModal must be used within ModalContextProvider');
    }

    return context;
}
