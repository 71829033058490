export const SET_DARK_MODE = Symbol('SET_DARK_MODE');
export const SET_HELP_ON_LOAD = Symbol('SET_HELP_ON_LOAD');

export const initialState = () => {
    const localSettings = JSON.parse(localStorage.getItem('timely-settings') ?? 'null');

    return {
        darkMode: localSettings?.darkMode ?? window.matchMedia('(prefers-color-scheme: dark)').matches,
        helpOnLoad: localSettings?.helpOnLoad ?? true,
    };
};

export default (state, action) => {
    let settings;

    switch (action.type) {
        case SET_DARK_MODE:
            settings = {
                ...state,
                darkMode: action.payload,
            };
            break;

        case SET_HELP_ON_LOAD:
            settings = {
                ...state,
                helpOnLoad: action.payload,
            };
            break;

        default:
            settings = state;
    }

    localStorage.setItem('timely-settings', JSON.stringify(settings));
    return settings;
};
