import { createContext, useContext, useReducer } from "react";

import statsReducer, { initialState } from "./stats-reducer";

const StatsContext = createContext();

export function StatsContextProvider({ children, maxRounds }) {
    const [stats, statsDispatch] = useReducer(statsReducer, initialState(maxRounds));

    return (
        <StatsContext.Provider value={[stats, statsDispatch]}>
            {children}
        </StatsContext.Provider>
    );
}

export function useStats() {
    const context = useContext(StatsContext);

    if (!context) {
        throw new Error('useStats must be used within StatsContextProvider');
    }

    return context;
}
