import { isYesterday, parseISO } from "date-fns";

export const SET_GAME_STATUS = Symbol('SET_GAME_STATUS');

export const initialState = (maxRounds) => {
    const localStats = JSON.parse(localStorage.getItem('timely-stats') ?? 'null');

    // Make it backwards compatible
    if (localStats?.attempts.win) {
        localStats.attempts = {
            0: localStats.attempts[1],
            1: localStats.attempts[2],
            2: localStats.attempts[3],
            3: localStats.attempts[4],
            4: localStats.attempts[5],
            5: localStats.attempts.win,
        };
    }

    return {
        streak: localStats?.streak ?? 0,
        maxStreak: localStats?.maxStreak ?? 0,
        gamesPlayed: localStats?.gamesPlayed ?? 0,
        attempts: localStats?.attempts ?? {
            ...[...new Array(maxRounds)].reduce((pre, cur, idx) => ({ ...pre, [idx]: 0 }), {}),
        },
        lastPlayed: localStats?.lastPlayed ? parseISO(localStats.lastPlayed) : undefined,
    };
};

export default (state, action) => {
    let stats;

    switch (action.type) {
        case SET_GAME_STATUS:
            const onStreak = state.lastPlayed ? isYesterday(state.lastPlayed) : false;
            const gameWon = action.payload.status === 'success';
            const attemptsIndex = gameWon ? 5 : action.payload.roundIndex - 1;

            const streak = onStreak ? state.streak + 1 : 1;

            stats = {
                ...state,
                streak,
                maxStreak: streak > state.maxStreak ? streak : state.maxStreak,
                gamesPlayed: state.gamesPlayed + 1,
                attempts: {
                    ...state.attempts,
                    [attemptsIndex]: state.attempts[attemptsIndex] + 1,
                },
                lastPlayed: new Date(),
            };
            break;

        default:
            stats = state;
    }

    localStorage.setItem('timely-stats', JSON.stringify(stats));
    return stats;
};
